import image1 from "./img/image1.png";
import image2 from "./img/image2.png";
import image3 from "./img/image3.png";
import image4 from "./img/image4.png";
import image5 from "./img/image5.png";
import image6 from "./img/image6.png";

export const products = [
  {
    id: 1,
    img: image1,
    link: "http://pisportal.pisonitsha.com/login",
  },
  {
    id: 2,
    img: image2,
    link: "https://github.com/ayowilfred95/qoinpal_crypto",
  },
  {
    id: 3,
    img: image3,
    link: "https://github.com/ayowilfred95/HNG-Stage2",
  },
  {
    id: 4,
    img: image4,
    link: "https://github.com/ayowilfred95/waitlist",
  },
  {
    id: 5,
    img: image5,
    link: "https://github.com/ayowilfred95/FlutterFlow-Ecommerce-backend",
  },
  {
    id: 6,
    img: image6,
    link: "https://wilfred9.hashnode.dev/",
  },
];
