import React from "react";
import "./Intro.css";
import Me from "../img/Me.png";

function Intro() {
  return (
    <div className="i">
      <div className="i-left">
        <div className="i-left-wrapper">
          <h2 className="i-intro">Hello, My name is</h2>
          <h1 className="i-name">Ayomide Wilfred</h1>
          <div className="i-title">
            <div className="i-title-wrapper">
              <div className="i-title-item">Backend Developer</div>
              <div className="i-title-item">Technical Writer</div>
              <div className="i-title-item">Software Developer</div>
              <div className="i-title-item">Mobile App Developer</div>
            </div>
          </div>
          <p className="i-description">
            👨‍💻I specialize in designing and building backend services,
            implementing API's used, server-side logic, databases used
            internally by products and underlying infrastructure that supports
            the front-end user experience.⌨️  
           <p>
           🖥 Additionally, I have extensive
            experience in blockchain technology, enabling me to integrate
            decentralized systems and smart contracts into your projects.
            </p> 
         
          </p>
          
        </div>
      </div>
      <div className="i-right">
        <div className="i-bg"></div>
        <img src={Me} alt="myimage" className="i-img" />
      </div>
    </div>
  );
}

export default Intro;
