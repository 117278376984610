import './about.css';

function About() {
  return (
    <div className='a'>
        <div className="a-left">
         <div className="a-card bg"></div>
        <div className="a-card">
            <img src="https://media.istockphoto.com/id/1358843391/photo/a-man-photographer-with-a-camera-takes-a-photo-in-the-dark-copy-space.jpg?s=612x612&w=0&k=20&c=6wpN7xto8t13IS1DTMCY05BZm_dGImg4Kg2DrjtbYbw=" alt="" className="a-img" />
        </div> 
        </div>
        <div className="a-right">
            <h1 className="a-title">🧑‍💼 About Me</h1>
            <p className="a-sub">
            As a Backend Developer
            </p>
            <p className="a-desc">
            🚀I thrive in crafting backend solutions that not only meet the functional requirements of a project but also prioritize performance, security, and scalability. From designing and implementing RESTful APIs to developing microservices and handling data storage.

            </p>
            <h3> 💡I have honed my skills in developing scalable backend architectures, designing and implementing APIs, and integrating complex systems
            </h3>
            <div className="a-award">
                <img src="https://media.istockphoto.com/id/1347499538/photo/close-up-low-angle-view-group-of-star-shape-crystal-trophy.jpg?b=1&s=612x612&w=0&k=20&c=xYACIFsXh_rzbFJoBXJ97juLJ6OHePEY_m64L7QJ5SU=" alt="" className="a-award-img" />
                <div className="a-award-texts">
                    <h4 className="a-award-title">🛠️ weapons of choice</h4>
                    <div className="a-award-desc">
                    <ul>Typescript(Nodejs)</ul>
                    <ul>Golang(Fiber/Gin)</ul>
                    <ul>NextJS/React</ul>
                    <ul>MongoDB</ul>
                    <ul>Postgres/MySql</ul>
                    <ul>Docker</ul>
                    <ul>Prisma/Sequalize</ul>
                    </div>
                
                </div>
            </div>
        </div>
    </div>
  )
}

export default About;
